<template>
  <Dialog title="Ustaw rabat" :onClose="close">
      <template v-slot:body>
          <div class="row">
              <div class="col-12">
                  <input-field :label="discountFieldLabel" name="discount" type="decimal" v-model="discount" :precision="discountFieldPrecision"></input-field>
              </div>
          </div>
          <div class="row">
              <div class="col-12">
                  <input-field label="Valid From" name="dateValidFrom" type="date" v-model="dateValidFrom"></input-field>
              </div>
          </div>
          <div class="row" style="margin-bottom: 220px;">
              <div class="col-12">
                  <input-field label="Valid To" name="dateValidTo" type="date" v-model="dateValidTo"></input-field>
              </div>
          </div>
      </template>
      <template v-slot:footer>
          <div class="row">
              <div class="col-12 text-end">
                  <b-btn variant="default" class="me-1" @click="onCancel">Anuluj</b-btn>
                  <b-btn variant="success" @click="onOk">OK</b-btn>
              </div>
          </div>
      </template>
  </Dialog>
</template>

<script>
import Dialog from "@/pages/Common/Dialog.vue";
import InputField from "@/components/Forms/InputField.vue";
export default {
    name: "DiscountChangeDialog",
    components: {Dialog, InputField},
    props: {
        onSet: {
            type: Function,
            required: true
        },
        discountFieldLabel: {
            type: String,
            required: false,
            default: 'Rabat'
        },
        discountFieldPrecision: {
            type: Number,
            required: false,
            default: 2
        }
    },
    data() {
        return {
            discount: 0.00,
            dateValidFrom: moment().format('YYYY-MM-DD'),
            dateValidTo: moment('2100-12-31').format('YYYY-MM-DD')
        }
    },
    methods: {
        onOk() {
            if (this.onSet) {
                this.onSet(this.discount, this.dateValidFrom, this.dateValidTo);
            }
            this.close();
        },
        onCancel() {
            this.close();
        },
        close() {
            this.$emit('close');
        }
    }
}
</script>

<style scoped>

</style>